import React from 'react'
import PostPreview from './PostPreview'
import { List, ListItem } from '../ui'

const PostList = ({ posts }) => (
  <List
    mx={[0, -2]}
    flexWrap="wrap"
    flexDirection="row"
    borderTop="1px solid #ddd"
    className="post-list"
  >
    {posts.map((post) => (
      <ListItem
        key={post.uri}
        width={[1, 1 / 2]}
        px={[0, 2]}
        pb={3}
      >
        <PostPreview
          {...post}
        />
      </ListItem>
    ))}
  </List>
)

export default PostList
