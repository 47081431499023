import React from 'react'
import { Heading, Text, Link, Box } from '../ui'
import PostThumbnailRemote from './PostThumbnailRemote'
import PostExcerpt from './PostExcerpt'
import { backgroundColor, padding, paddingTop, position } from 'styled-system'

const TopStoryStyles = (props) => (props.topstory && {
  margin: "-50px 20px 20px 20px",
  padding: "1px 20px 20px 20px"
});

const PostPreview = ({
  uri, title, excerpt, meta, featuredImage, imageHeight, topstory
}) => (
  <article>
    <Link to={uri}>
      <PostThumbnailRemote
        alt={title}
        {...featuredImage.node}
        {...featuredImage.node.mediaDetails}
        height={topstory ? 360 : 170}
      />
      <Box
          style={{
            position: 'relative',
            backgroundColor: 'white',
            ...TopStoryStyles({ topstory })
        }}
      >
      <Heading
        mt={3}
        as="h2"
        fontSize={topstory ? 5 : 3}
        lineHeight={1.35}
        fontFamily={'Merriweather, serif'}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Text
        mt={1}
        variant="description"
      >
        {meta}
      </Text>
      <PostExcerpt my={3}>
        {excerpt ? excerpt.replace(/<[^>]+>/g, '') : ''}
      </PostExcerpt>
      </Box>
    </Link>
  </article>
)

export default PostPreview
